// import { lazy } from 'react'
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'
import { Navigate } from 'react-router-dom'

const SpaceLayout = reactLazyWithRetry(() => import('@/layouts/SpaceLayout'))

const AuditLogs = reactLazyWithRetry(() => import('@/pages/AuditLog/AuditLogs'))
const SpaceSetupGuide = reactLazyWithRetry(
  () => import('@/pages/Locations/Setup/SpaceSetupGuide')
)

const AddressBook = reactLazyWithRetry(
  () => import('../pages/AddressBook/AddressBook')
)

const Assets = reactLazyWithRetry(() => import('../pages/Assets/Assets'))

const ChangeLog = reactLazyWithRetry(() => import('../pages/ChangeLog'))
const EditLocationForm = reactLazyWithRetry(
  () => import('../pages/Locations/EditLocationForm')
)
const LocationsManage = reactLazyWithRetry(
  () => import('../pages/Locations/LocationsManage')
)

const SpaceDashboard = reactLazyWithRetry(
  () => import('../pages/Dashboard/SpaceDashboard')
)
const AddLockPermissionsForm = reactLazyWithRetry(
  () => import('../pages/Lock/AddLockPermissionsForm')
)

const AddMembersForm = reactLazyWithRetry(
  () => import('../pages/Members/AddMembersForm')
)

const BatchAddMembers = reactLazyWithRetry(
  () => import('../pages/Members/BatchAddMembers')
)
const BatchEnterMembers = reactLazyWithRetry(
  () => import('../pages/Members/BatchEnterMembers')
)

const MemberDeskForm = reactLazyWithRetry(
  () => import('../pages/Members/MemberDeskForm')
)

const MemberInfo = reactLazyWithRetry(
  () => import('../pages/Members/MemberInfo')
)

const Members = reactLazyWithRetry(() => import('../pages/Members/Members'))

const AddAccessRole = reactLazyWithRetry(
  () => import('../pages/Roles/AddAccessRole')
)

const MemberRoleManage = reactLazyWithRetry(
  () => import('../pages/Roles/MemberRoleManage')
)

const EditAccessRole = reactLazyWithRetry(
  () => import('../pages/Roles/Permissions')
)

const SpaceSettings = reactLazyWithRetry(
  () => import('../pages/SpaceSetting/SpaceSettings')
)
const SpaceServiceBox = reactLazyWithRetry(
  () => import('../pages/SpaceSetting/SpaceServiceBox')
)
const SpaceServiceDetail = reactLazyWithRetry(
  () => import('../pages/SpaceSetting/SpaceServiceDetail')
)
const SpaceSubscriptions = reactLazyWithRetry(
  () => import('../pages/SpaceSetting/SpaceSubscriptions')
)

const UserSetting = reactLazyWithRetry(
  () => import('../pages/User/UserSetting')
)

const Webhook = reactLazyWithRetry(() => import('../pages/Webhook/Webhook'))

const WebHookForm = reactLazyWithRetry(
  () => import('../pages/Webhook/WebHookForm')
)

const WorkSpace = reactLazyWithRetry(
  () => import('../pages/WorkSpace/WorkSpaceApprovals')
)
const SpaceBasicSettings = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceBasicSettings')
)
const SpaceTagGroupSettings = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceTagGroupSettings')
)
const SpaceTagSettings = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceTagSettings')
)
const SpaceAllTagSettings = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceAllTagSettings')
)
const SpaceBrandingSettings = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceBrandingSettings')
)
const BillingCenterLayout = reactLazyWithRetry(
  () => import('@/pages/BillingCenter/BillingCenterLayout')
)
const SubscriptionsInfo = reactLazyWithRetry(
  () => import('@/pages/BillingCenter/Invoice.jsx')
)
const SpaceDeskAnalysis = reactLazyWithRetry(
  () => import('@/pages/DataAnalysis/SpaceDeskAnalysis.jsx')
)

const SpaceInitialize = reactLazyWithRetry(
  () => import('@/pages/Locations/SpaceInitialize')
)
const DefaultCreateLocation = reactLazyWithRetry(
  () => import('@/pages/Locations/DefaultCreateLocation')
)
const LegalDocument = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/LegalDocument.jsx')
)
const VisitorTypeSetting = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/visitorTypeSetting')
)
const SpaceVisitorLayout = reactLazyWithRetry(
  () => import('@/pages/Visitor/SpaceVisitorLayout')
)
const VisitorAddVisitorType = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/VisitorAddVisitorType')
)
const EditVisitorType = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/EditVisitorType')
)
const SpaceServiceInfo = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/components/SpaceServiceInfo')
)

const NoSubscriptionContent = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)

const UnsubscribedPage = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)

const NoSubscriptionPage = reactLazyWithRetry(
  () => import('@/pages/NoSubscriptionPage')
)
const SpaceAllDynamicAttribute = reactLazyWithRetry(
  () => import('@/pages/SpaceSetting/SpaceAllDynamicAttribute')
)
const EnergyConsumptionStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/EnergyConsumptionStatistic')
)
const RepairStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/RepairStatistic')
)
const DeliveryStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/DeliveryStatistic')
)

const MeetingStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/MeetingStatistic')
)
const DeskStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/DeskStatistic')
)
const VisitorStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/VisitorStatistics')
)
const SpaceHybridWorkStatistic = reactLazyWithRetry(
  () => import('@/pages/Statistics/SpaceDir/HybridWorkStatistic')
)

const AdminLayout = reactLazyWithRetry(() => import('@/layouts/AdminLayout'))
const DefaultInviteMembers = reactLazyWithRetry(
  () => import('@/pages/Locations/DefaultInviteMembers')
)

const isChina = import.meta.env.VITE_REGION === 'China'

const routes = {
  path: 'space',
  context: 'space',

  element: <SpaceLayout />,
  permission: 'all',
  children: [
    {
      name: 'location.index',
      icon: 'menu-data_analysis_line',
      path: '',
      index: true,
      hideInMenu: true,
      permission: 'all',

      element: <Navigate to="/admin/space/dashboard" replace={true} />,
    },
    {
      name: 'location.dashboard',
      icon: 'menu-data_analysis_line',
      path: 'dashboard',
      permission: 'all',

      element: <SpaceDashboard />,
    },
    {
      name: 'location.manage',
      icon: 'menu-branch_store_fill',
      path: 'locations/manage',
      permission: 'all',

      element: <LocationsManage />,
    },
    // 工作台
    {
      name: 'workspace',
      path: 'workspace',
      permission: 'all',

      element: <WorkSpace />,
      hideInMenu: true,
    },
    {
      name: 'location.add',
      path: 'locations/add',
      permission: 'space.location.create',

      element: <EditLocationForm />,
      hideInMenu: true,
    },
    {
      name: 'member.manage',
      icon: 'menu-employee_management_line',
      path: 'members/manage',
      permission: 'all',

      element: <Members />,
    },
    // {
    //   name: 'setup',
    //   icon: 'member',
    //   path: 'setup',
    //   permission: 'all',
    //   element: <SpaceSetupGuide />,
    // },
    {
      name: 'member',
      icon: 'member',
      path: 'members',
      permission: 'space.member.index',
      hideInMenu: true,
      children: [
        // TODO: 暂时关闭
        // {
        //     component: '../Members/AccessRole',
        //     name: 'access',
        //     path: 'access',
        // },
        {
          element: <AddAccessRole />,
          name: 'member.access_create',
          path: 'access/create',
          permission: 'space.access_role.create',
          hideInMenu: true,
        },
        {
          element: <EditAccessRole />,
          name: 'member.access_edit',
          path: 'access/edit',
          permission: 'space.access_role.update',
          hideInMenu: true,
        },
        {
          element: <MemberInfo />,
          name: 'member.info',
          path: 'info',
          permission: 'all',
          hideInMenu: true,
        },
        {
          element: <MemberDeskForm />,
          name: 'member.desk',
          path: 'desk',
          permission: 'all',
          hideInMenu: true,
        },
        {
          element: <BatchAddMembers />,
          name: 'member.batch_add_members',
          path: 'batch_add_members',
          permission: 'space.member.import_members',
          hideInMenu: true,
        },
        {
          element: <AddMembersForm />,
          name: 'member.add',
          path: 'add',
          permission: 'space.member.create',
          hideInMenu: true,
        },
        {
          element: <BatchEnterMembers />,
          name: 'member.batch_enter_members',
          path: 'batch_enter',
          permission: 'space.member.import_members',
          hideInMenu: true,
        },
      ],
    },
    {
      name: 'member.roles',
      path: 'roles',
      icon: 'menu-permission_management_line',
      permission: 'space.access_role.index',

      element: <MemberRoleManage />,
    },
    {
      name: 'assets',
      path: 'assets',
      icon: 'menu-asset_management_line',
      permission: 'space.device.manage',

      element: <Assets />,
    },

    {
      name: 'dataAnalysis',
      path: 'analytics',
      icon: 'menu-data_analysis_line',
      // permission: 'space.subscription.index',
      permission: 'space.analytics.manage',
      children: [
        {
          name: 'dataAnalysis.deskAnalysis',
          path: 'desks',
          permission: 'space.analytics.manage',
          // permission: 'space.subscription.index',

          element: <SpaceDeskAnalysis />,
        },
      ],
    },
    // disable for China version
    {
      name: 'subscriptions',
      path: 'subscriptions',
      icon: 'menu-expense_center_line',
      permission: 'space.saas.subscription.index',

      element: <BillingCenterLayout />,
      // hideInMenu: isChina,
      hideInMenu: true,
    },
    {
      name: 'subscriptions.info',
      path: 'subscriptions/info',
      permission: 'space.saas.subscription.index',
      hideInMenu: true,

      element: <SubscriptionsInfo />,
    },
    // 混合办公
    {
      name: 'desk.analytics',
      path: 'hybridWork',
      icon: 'menu-large_data_screen',
      permission: 'location.location.manage',
      children: [
        {
          name: 'hybridWork',
          path: 'analytics',
          permission: 'location.location.manage',

          element: <SpaceHybridWorkStatistic />,
        },
        {
          element: <MeetingStatistic />,
          name: 'statistics.meeting.analysis',
          path: 'meeting_analysis',
          permission: 'location.location.manage',
          // module: "tasks",
          // moduleLevel: "pro",
        },
        {
          element: <DeskStatistic />,
          name: 'statistics.desk.analysis',
          path: 'desk_analysis',
          permission: 'location.location.manage',
          // module: "tasks",
          // moduleLevel: "pro",
        },

        {
          element: <VisitorStatistic />,
          name: 'statistics.visitor.analysis',
          path: 'visitor_analysis',
          permission: 'location.location.manage',
          // module: "tasks",
          // moduleLevel: "pro",
        },
        {
          element: <RepairStatistic />,
          name: 'statistics.repair.analysis',
          path: 'repair_analysis',
          permission: 'location.location.manage',
          // module: "tasks",
          // moduleLevel: "pro",
        },
        {
          element: <DeliveryStatistic />,
          name: 'statistics.delivery.analysis',
          path: 'delivery_analysis',
          permission: 'location.location.manage',
          modules: ['task_delivery'],
          // moduleLevel: "pro",
        },
        {
          element: <EnergyConsumptionStatistic />,
          name: 'statistics.energyConsumpt',
          path: 'energy_consumption',
          permission: 'location.location.manage',
          hideInMenu: !isChina,
          // module: "tasks",
          // moduleLevel: "pro",
        },
      ],
    },
    {
      name: 'visitor',
      path: 'visiter_settings',
      icon: 'menu-visitor_line',
      permission: 'space.visitors.setting.index',
      // module: "visitor",

      element: <SpaceVisitorLayout />,

      children: [
        {
          name: 'visitors.settings.types',
          path: 'types',
          permission: 'space.visitors.setting.index',

          element: <VisitorTypeSetting />,
        },
        {
          name: 'visitor.legalDocument',
          path: 'legal_documents',
          permission: 'space.visitors.setting.index',

          element: <LegalDocument />,
        },
        {
          name: 'visitors.add_type',
          path: 'add-type',
          hideInMenu: true,
          permission: 'space.visitors.setting.manage',

          element: <VisitorAddVisitorType />,
        },
        {
          name: 'visitors.settings.edit_type',
          path: 'edit-type/:visitorTypeIndex',
          hideInMenu: true,
          permission: 'all',

          element: <EditVisitorType />,
        },
      ],
    },

    {
      name: 'settings',
      path: 'settings',
      icon: 'menu-branch_setting_line',
      permission: 'space.setting.index',
      // element: <SpaceSettings />,
      children: [
        {
          name: 'settings.basic',
          path: 'basic',
          permission: 'space.setting.index',

          element: <SpaceBasicSettings />,
        },
        {
          name: 'settings.branding',
          path: 'branding',
          permission: 'space.setting.index',

          element: <SpaceBrandingSettings />,
        },
        // {
        //   name: 'settings.tagGroup',
        //   path: 'tag_group',
        //   permission: 'space.setting.index',
        //   element: <SpaceTagGroupSettings />
        // },
        {
          name: 'settings.field',
          path: 'tag',
          permission: 'space.setting.index',

          element: <SpaceAllTagSettings />,
          // element: <SpaceTagSettings />
        },
        // {
        //   name: "settings.dynamic_attribute",
        //   path: "dynamic_attribute",
        //   permission: "space.setting.index",
        //   element: <SpaceAllDynamicAttribute />,
        //   // element: <SpaceTagSettings />
        // },
      ],
    },
    {
      name: 'setup',
      path: 'setup',
      permission: 'space.location.create',

      element: <SpaceSetupGuide />,
      hideInMenu: true,
    },

    {
      name: 'setup',
      permission: 'space.location.create',
      path: 'spaceInitialize',

      element: <SpaceInitialize />,
      hideInMenu: true,
    },

    {
      path: 'createFirstLocation',

      element: <DefaultCreateLocation />,
      hideInMenu: true,
    },
    {
      path: 'inviteMembers',

      element: <DefaultInviteMembers />,
      hideInMenu: true,
    },
    {
      name: 'integrations',
      path: 'integrations',
      icon: 'menu-service_integration_line',
      permission: 'space.setting.index',

      element: <SpaceServiceBox />,
    },
    {
      path: 'integrations/info',
      permission: 'space.setting.index',
      hideInMenu: true,

      element: <SpaceServiceInfo />,
    },
    {
      name: 'subscriptions.accounts',
      path: 'webhook',
      icon: 'spaceSettings',
      permission: 'space.setting.index',
      hideInMenu: true,
      children: [
        {
          name: '',
          path: '',
          permission: 'space.setting.index',

          element: <Webhook />,
          hideInMenu: true,
        },
        {
          name: 'add',
          path: 'add',
          permission: 'space.setting.index',

          element: <WebHookForm />,
          hideInMenu: true,
        },
      ],
    },
    // {
    //   name: 'dataScreen',
    //   icon: 'menu-large_data_screen',
    //   path: 'screen',
    //   permission: 'space.location.create',
    //   element: <Screen />,
    // },
    {
      name: 'operations_record',
      path: 'operations',
      icon: 'menu-operation_record_line',
      permission: 'space.audit_log.index',

      element: <AuditLogs />,
    },
    // 更新详情
    {
      name: 'change_log',
      path: 'changelogs',
      icon: 'spaceSettings',

      element: <ChangeLog />,
      permission: 'space.settings.index',
      hideInMenu: true,
    },
    {
      name: 'setting_service',
      path: 'settings/service',
      icon: 'spaceSettings',
      permission: 'space.setting.index',

      element: <SpaceServiceDetail />,
      hideInMenu: true,
    },
    {
      name: 'add_lock_permissions',
      path: 'add-lock-permissions',
      permission: 'location.lock_permission.batch_create',

      element: <AddLockPermissionsForm />,
      hideInMenu: true,
    },
    {
      element: <NoSubscriptionPage />,
      name: 'location.noSubscription',
      path: 'subscriptions/noSubscription',
      hideInMenu: true,
    },
  ],
}

export default routes
