// import { lazy } from 'react'
import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy'
import { Navigate } from 'react-router-dom'

const AddressBook = reactLazyWithRetry(
  () => import('@/pages/AddressBook/AddressBook')
)

const UserSetting = reactLazyWithRetry(() => import('@/pages/User/UserSetting'))

const MemberInfo = reactLazyWithRetry(
  () => import('@/pages/Members/MemberInfo')
)

const EmployeeLocationLayout = reactLazyWithRetry(
  () => import('@/layouts/EmployeeLocationLayout')
)

const Homepage = reactLazyWithRetry(() => import('@/pages/Locations/Homepage'))
const BookingRecords = reactLazyWithRetry(
  () => import('@/pages/Locations/BookingRecords/BookingRecords')
)

const MeetingRoomLayout = reactLazyWithRetry(
  () => import('@/layouts/MeetingRoomLayout')
)

const ActivityList = reactLazyWithRetry(
  () => import('@/pages/Activity/ActivityList')
)

const Announcements = reactLazyWithRetry(
  () => import('@/pages/Announcements/Announcements')
)

const BorrowingForm = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingForm')
)
const BorrowingHistory = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingHistory')
)
const BorrowingLoanForm = reactLazyWithRetry(
  () => import('@/pages/Borrowings/BorrowingLoanForm')
)

const Borrowings = reactLazyWithRetry(
  () => import('@/pages/Borrowings/Borrowings')
)

const CabinetRecord = reactLazyWithRetry(
  () => import('@/pages/Cabinets/CabinetRecord')
)

const TaskList = reactLazyWithRetry(
  () => import('@/pages/ControlRepair/TaskList')
)

const AddAreaForm = reactLazyWithRetry(
  () => import('@/pages/Desk/Areas/AreaFormModal')
)

const BatchArea = reactLazyWithRetry(() => import('@/pages/Desk/BatchArea'))
const ColleagueCalendar = reactLazyWithRetry(
  () => import('@/pages/Desk/Calendar/ColleagueCalendar')
)

const DeskLayout = reactLazyWithRetry(() => import('@/layouts/DeskLayout'))
const ReservationRecords = reactLazyWithRetry(
  () => import('@/pages/Desk/Reservations/ReservationRecords')
)
const ReservationDesks = reactLazyWithRetry(
  () => import('@/pages/Desk/Reservations/ReservationDesks')
)

const AlertRecord = reactLazyWithRetry(
  () => import('@/pages/Device/AlertRecord')
)

const Lighting = reactLazyWithRetry(() => import('@/pages/Light/Light'))

const BuildingForm = reactLazyWithRetry(
  () => import('@/pages/Locations/BuildingForm')
)
const LocationSettingBoarding = reactLazyWithRetry(
  () => import('@/pages/Locations/LocationSettingBoarding')
)
const LocationSetupGuide = reactLazyWithRetry(
  () => import('@/pages/Locations/Setup/LocationSetupGuide')
)
const AddMeetingDeviceForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/AddMeetingDeviceForm')
)

const AddMeetingForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/AddMeetingForm')
)

const EditMeetingForm = reactLazyWithRetry(
  () => import('@/pages/Meetings/EditMeetingForm')
)

const ImportMeeting = reactLazyWithRetry(
  () => import('@/pages/Meetings/ImportMeeting')
)

const MeetingRooms = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingRooms')
)

const MeetingSensor = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingSensor')
)

const MeetingSensors = reactLazyWithRetry(
  () => import('@/pages/Meetings/MeetingSensors')
)

const NewMeetingInfo = reactLazyWithRetry(
  () => import('@/pages/Meetings/NewMeetingInfo')
)

const ReservationInfo = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationInfo')
)
const ReservationManage = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationManage')
)
const ReservationRecord = reactLazyWithRetry(
  () => import('@/pages/Meetings/ReservationRecord')
)

const AddMembersForm = reactLazyWithRetry(
  () => import('@/pages/Members/AddMembersForm')
)
const BatchEnterMembers = reactLazyWithRetry(
  () => import('@/pages/Members/BatchEnterMembers')
)

const MemberDeskForm = reactLazyWithRetry(
  () => import('@/pages/Members/MemberDeskForm')
)

const Members = reactLazyWithRetry(() => import('@/pages/Members/Members'))
const MemberAddLocationRole = reactLazyWithRetry(
  () => import('@/pages/Roles/MemberAddLocationRole')
)

const ScreenForm = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenForm')
)

const ScreenManage = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenManage')
)
const ScreenTemplateForm = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenTemplateForm')
)
const ScreenTemplatesManage = reactLazyWithRetry(
  () => import('@/pages/Screens/ScreenTemplatesManage')
)

const UserSettingForm = reactLazyWithRetry(
  () => import('@/pages/User/UserSettingForm')
)
const AddVisitorDeviceForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/AddVisitorDeviceForm')
)

const InviteForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/InviteForm')
)
const VisitorAddVisitorType = reactLazyWithRetry(
  () => import('@/pages/Visitor/Settings/Types/VisitorAddVisitorType')
)

const SignInForm = reactLazyWithRetry(
  () => import('@/pages/Visitor/SignInForm')
)
const VisitorDetailPage = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorDetail/VisitorDetailPage')
)
const VisitorInviteList = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorInviteList')
)

const VisitorLayout = reactLazyWithRetry(
  () => import('@/pages/Visitor/VisitorLayout')
)

const EmployeeHome = reactLazyWithRetry(
  () => import('@/pages/Locations/EmployeeHome')
)

const ExceptionNoLocation = reactLazyWithRetry(
  () => import('@/pages/Exception/ExceptionNoLocation')
)

const ActivityLayout = reactLazyWithRetry(
  () => import('@/layouts/ActivityLayout')
)

// all paths starts with /user
const routes = {
  path: '',
  // element: <Navigate to="/user/locations/homepage" replace />,
  children: [
    {
      path: '',

      element: <Navigate to="/user/locations" replace />,
    },
    {
      element: <MemberInfo />,
      name: 'member.info',
      path: 'space/members/info',
      permission: 'all',
      hideInMenu: true,
    },
    {
      name: 'user_setting',
      path: 'user-settings',
      icon: 'spaceSettings',

      element: <UserSetting />,
      permission: 'all',
      hideInMenu: true,
    },
    {
      name: 'address_book',
      path: 'address-book',
      icon: 'team',

      element: <AddressBook />,
      permission: 'all',
      hideInMenu: true,
    },
    {
      path: 'locations',

      element: <EmployeeLocationLayout />,
      context: 'location',
      permission: 'all',
      children: [
        /**
         * 分店首页
         */
        {
          name: 'location.user',
          icon: 'menu-home_line',
          index: true,
          hideInMenu: true,
          permission: 'all',

          element: <EmployeeHome />,
        },
        {
          name: 'location.home',
          icon: 'menu-home_line',
          path: 'homepage',
          permission: 'all',
          modules: ['desk', 'meeting'],

          element: <Homepage />,
        },
        {
          name: 'desk.reservations',
          // icon: 'menu-home_line',
          path: 'bookingRecords',
          permission: 'all',
          modules: ['desk', 'meeting'],
          hideInMenu: true,
          element: <BookingRecords />,
        },
        // {
        //   path: "bookDesk",
        //   permission: "all",
        //   hideInMenu: true,
        //   element: <BookDesk />,
        // },
        {
          name: 'calendar',
          path: 'calendar',
          icon: 'menu-calendar_line',
          permission: 'all',
          modules: ['hybridWork'],

          element: <ColleagueCalendar />,
        },
        // 工位预定
        {
          name: 'desk',
          modules: ['desk'],
          path: 'desk',
          permission: 'all',
          icon: 'menu-station_line',

          element: <DeskLayout />,
          children: [
            {
              element: (
                <Navigate to="/user/locations/desk/reservation" replace />
              ),
              name: 'index',
              path: '',
              index: true,
            },
            // 工位预定
            {
              name: 'desk.reservation',
              path: 'reservation',
              permission: 'all',

              element: <ReservationDesks />,
            },
            {
              name: 'desk.reservations',
              path: 'reservations',
              permission: 'all',

              element: <ReservationRecords />,
            },
            {
              name: 'desk.history',
              path: ':location_id/desk/reservations/:reservation_id',
              permission: 'all',

              element: <ReservationRecords />,
              hideInMenu: true,
            },
          ],
        },
        {
          element: <ReservationInfo />,
          modules: ['meeting'],
          name: 'meeting.reservation_info',
          path: ':location_id/meetingReservation/:reservation_id',
          permission: 'all',
          hideInMenu: true,
        },
        {
          element: <ImportMeeting />,
          modules: ['meeting'],
          name: 'meeting.importFromCalendars',
          path: ':location_id/importMeeting',
          permission: 'all',
          hideInMenu: true,
        },
        // 会议室
        {
          name: 'meeting',
          modules: ['meeting'],
          path: 'meeting',
          icon: 'menu-conference_room_line',
          permission: 'all',

          element: <MeetingRoomLayout />,
          children: [
            {
              element: (
                <Navigate to="/user/locations/meeting/reservation" replace />
              ),
              name: 'index',
              path: '',
              index: true,
            },
            {
              name: 'meeting.reservation',
              path: 'reservation',
              permission: 'all',

              element: <ReservationManage />,
            },
            {
              name: 'meeting.statistics',
              path: 'list',
              permission: 'all',

              element: <MeetingRooms />,
            },
            {
              name: 'meeting.reservations',
              path: 'reservations',
              permission: 'all',

              element: <ReservationRecord />,
            },

            {
              element: <AddMeetingForm />,
              name: 'meeting.add',
              path: 'list/add',
              permission: 'all',
              hideInMenu: true,
            },
            {
              name: 'meeting.edit',
              path: 'list/edit',

              element: <EditMeetingForm />,
              permission: 'all',
              hideInMenu: true,
            },
            {
              element: <NewMeetingInfo />,
              name: 'meeting.info',
              path: 'list/info',
              permission: 'all',
              hideInMenu: true,
            },
            // {
            //   name: 'business_detail',
            //   path: 'business_detail',
            //   permission: 'location.area.index',
            //   component: './Meetings/BusinessManagement/BusinessManagement',
            // },
            // {
            //   name: 'meeting.serviceOrders',
            //   path: 'meeting.serviceOrders',
            //   permission: 'location.area.index',
            //   component: './Meetings/BusinessDetail/BusinessDetail',
            // },
            // {
            //   name: 'emailSetting',
            //   path: 'emailSetting',
            //   permission: 'location.area.manage',
            //   element: <MeetingsEmailSetting />,
            // },
          ],
        },
        {
          name: 'alerts',
          path: 'alerts',

          element: <AlertRecord />,
          hideInMenu: true,
        },
        // 访客详情
        {
          name: 'visitors.detail',
          modules: ['visitor'],
          path: ':location_id/visitor/detail/:visitor_id/:visitor_type',
          hideInMenu: true,
          permission: 'all',

          element: <VisitorDetailPage />,
        },
        /**
         * 访客
         */
        {
          name: 'visitors',
          modules: ['visitor'],
          path: 'visitors',
          icon: 'menu-visitor_line',

          element: <VisitorLayout />,
          permission: 'all',
          children: [
            {
              name: 'visitors.index',
              path: '',
              index: true,
              permission: 'all',
              element: (
                <Navigate to="/user/locations/visitors/invites" replace />
              ),
            },
            {
              name: 'visitors.myVisitors',
              path: 'invites',
              permission: 'all',

              element: <VisitorInviteList />,
            },
            {
              name: 'visitors.devices.add',
              path: 'add-device',
              hideInMenu: true,
              permission: 'location.pad.create',

              element: <AddVisitorDeviceForm />,
            },
            {
              name: 'visitors.add_type',
              path: 'add-type',
              hideInMenu: true,
              permission: 'location.visitors.setting.manage',

              element: <VisitorAddVisitorType />,
            },
            {
              name: 'visitors.inviteForm',
              path: 'invite',
              hideInMenu: true,
              permission: 'all',

              element: <InviteForm />,
            },
            {
              name: 'visitors.SignInForm',
              path: 'signin',
              hideInMenu: true,
              permission: 'location.visitors.visitor.manage',

              element: <SignInForm />,
            },
          ],
        },

        /**
         * 智能储物柜
         */
        {
          name: 'cabinet.employee_records',
          path: 'cabinet/records',
          modules: ['locker'],
          icon: 'menu-locker',
          permission: 'all',

          element: <CabinetRecord />,
        },
        /**
         * 智能灯光
         */
        {
          name: 'light',
          icon: 'light',
          path: 'light',
          modules: ['actuator'],
          permission: 'sensor.switch',
          children: [
            {
              element: <Navigate to="/user/locations/light/control" replace />,
              name: 'index',
              path: '',
              index: true,
              permission: 'sensor.switch',
            },
            {
              element: <Lighting />,
              name: 'control',
              path: 'control',
              permission: 'sensor.switch',
            },
          ],
        },

        /**
         * 活动管理
         */
        {
          name: 'activity',
          path: 'activity',
          icon: 'menu-activity_line',
          permission: 'all',
          modules: ['activity'],

          element: <ActivityLayout />,
          children: [
            {
              name: 'activity.list',
              path: 'list',

              element: <ActivityList />,
              permission: 'all',
            },
          ],
        },
        // {
        //   name: "activity",
        //   path: "activity/list",
        //   icon: "menu-activity_line",
        //   modules: ["activity"],
        //   element: <ActivityList />,
        //   permission: "all",
        // },

        /**
         * 借还
         */
        {
          name: 'borrow',
          modules: ['borrowing'],
          path: 'borrow',
          icon: 'borrow',
          permission: '', // 暂时关闭
          children: [
            {
              element: <Navigate to="/user/locations/borrow/list" replace />,
              name: 'index',
              path: '',
              index: true,
            },
            {
              name: 'borrowing.list',
              path: 'list',

              element: <Borrowings />,
            },
            {
              name: 'borrowing.history',
              path: 'history',

              element: <BorrowingHistory />,
            },
            {
              name: 'borrowing.edit',
              path: 'edit',

              element: <BorrowingForm />,
              hideInMenu: true,
            },
            {
              name: 'borrowing.loan',
              path: 'loan',

              element: <BorrowingLoanForm />,
              hideInMenu: true,
            },
          ],
        },
        /**
         * 大屏
         */
        {
          name: 'screen',
          modules: ['signage'],
          // module: 'all',
          path: 'screen',
          icon: 'daping',
          permission: '', // 暂时关闭
          children: [
            {
              element: <Navigate to="/user/locations/screen/manage" replace />,
              name: 'index',
              path: '',
              index: true,
            },
            {
              element: <ScreenManage />,
              name: 'manage',
              path: 'manage',
            },
            {
              element: <ScreenForm />,
              name: 'new_screen',
              path: 'manage/new',
              hideInMenu: true,
            },
            {
              element: <ScreenTemplatesManage />,
              name: 'templates',
              path: 'templates',
            },
            {
              element: <ScreenTemplateForm />,
              name: 'new_screen_template',
              path: 'templates/new',
              hideInMenu: true,
            },
          ],
        },
        {
          name: 'announcements',
          path: 'announcements',
          modules: ['announcements'],
          permission: 'location.quick_message.index',

          element: <Announcements />,
          icon: 'menu-quick_message_line',
        },
        {
          name: 'add_device',
          path: 'devices/devices/add_device',
          permission: 'location.smart_device.manage',

          element: <AddMeetingDeviceForm />,
          hideInMenu: true,
        },
        // 人体传感器页面
        {
          name: 'sensors',
          path: 'devices/sensors',
          permission: 'location.smart_device.index',

          element: <MeetingSensors />,
          hideInMenu: true,
        },
        // 传感器详情页
        {
          name: 'sensor',
          path: 'devices/sensors/:id', // 传感器详情页
          permission: 'location.sensor.show',

          element: <MeetingSensor />,
          hideInMenu: true,
        },
        // 工单
        {
          name: 'tasksModule',
          path: 'tasks',
          icon: 'menu-repair_order_line',
          permission: 'all',
          children: [
            {
              element: <TaskList />,
              path: 'list',
              name: 'tasks.list',
              index: true,
            },
            {
              element: <TaskList />,
              name: 'delivery.list',
              path: 'delivery',
              modules: ['task_delivery'],
            },
          ],
        },

        /**
         * 公司员工
         */
        {
          name: 'member',
          icon: 'menu-employee_management_line',
          path: 'members',
          permission: 'all',
          children: [
            {
              element: <Members />,
              path: 'list',
              name: 'user.list',
              index: true,
            },
            {
              element: <Members />,
              name: 'user.userGroup',
              path: 'groups',
            },
          ],
        },

        {
          name: 'location.setup',
          path: 'setup',
          icon: 'record',
          permission: 'location.location.update',

          element: <LocationSetupGuide />,
          hideInMenu: true,
        },

        {
          name: 'location.member_info',
          path: 'members/:member_id',
          icon: 'member',

          element: <MemberInfo />,
          permission: 'all',
          hideInMenu: true,
        },
        {
          element: <MemberDeskForm />,
          name: 'location.desk_member',
          path: 'members/:member_id/desk-member',
          permission: 'location.member.update',
          hideInMenu: true,
        },
        {
          path: 'setting_boarding',
          name: 'setting_boarding',
          permission: 'setting.update',

          element: <LocationSettingBoarding />,
          hideInMenu: true,
        },
        {
          name: 'edit_building',
          path: 'edit_building',
          permission: 'location.building_info.update',

          element: <BuildingForm />,
          hideInMenu: true,
        },
        /**
         * 储物柜
         */
        // 隐藏菜单
        {
          name: 'user_setting',
          path: 'user-setting',
          icon: 'spaceSettings',

          element: <UserSettingForm />,
          permission: 'all',
          hideInMenu: true,
        },
        {
          path: 'add-area',
          name: 'add_area',
          hideInMenu: true,
          permission: 'area.create',

          element: <AddAreaForm />,
        },
        {
          path: 'batch-area/:floorId',
          name: 'batch_area',
          permission: 'location.area.batch_create',
          hideInMenu: true,

          element: <BatchArea />,
        },
        {
          element: <AddMembersForm />,
          name: 'location.add_member',
          path: 'members/add',
          permission: 'location.member.create',
          hideInMenu: true,
        },
        {
          element: <BatchEnterMembers />,
          name: 'batch_enter_members',
          path: 'members/batch_enter',
          permission: 'location.member.import_members',
          hideInMenu: true,
        },
        {
          element: <MemberAddLocationRole />,
          name: 'location.add_role',
          path: 'members/add-role',
          permission: 'location.member.create',
          hideInMenu: true,
        },
        {
          path: 'no-location',
          name: 'no-location',

          element: <ExceptionNoLocation />,
          hideInMenu: true,
        },
      ],
    },
  ],
}
export default routes
